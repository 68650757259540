// Generate the large columns
@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-xl-min) {
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-xl-column-offset($columns) {
  @media (min-width: $screen-xl-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xl-column-push($columns) {
  @media (min-width: $screen-xl-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xl-column-pull($columns) {
  @media (min-width: $screen-xl-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  @include container-fixed();
  @media (min-width: $screen-xl-min) {
    width: $container-xl;
  }
}

// xLarge grid
//
// Columns, offsets, pushes, and pulls for the xlarge desktop device range.

@media (min-width: $screen-xl-min) {
  @include make-grid(xl);
}


// Visibility utilities
.visible-xl {
  @include responsive-invisibility('.visible-xl');
}

.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
  display: none !important;
}

.visible-lg,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    @include responsive-visibility('.visible-lg');
  }
}
.visible-lg-block {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    display: block !important;
  }
}
.visible-lg-inline {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    display: inline !important;
  }
}
.visible-lg-inline-block {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    display: inline-block !important;
  }
}

.visible-xl,
.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
  @media (min-width: $screen-xl-min) {
    @include responsive-visibility('.visible-xl');
  }
}
.visible-xl-block {
  @media (min-width: $screen-xl-min) {
    display: block !important;
  }
}
.visible-xl-inline {
  @media (min-width: $screen-xl-min) {
    display: inline !important;
  }
}
.visible-xl-inline-block {
  @media (min-width: $screen-xl-min) {
    display: inline-block !important;
  }
}

.hidden-xl {
  @media (min-width: $screen-xl-min) {
    @include responsive-invisibility('.hidden-xl');
  }
}