#zitat-2 {
    background: url(../images/home.jpg) no-repeat 50%;
    background-attachment: scroll;
    background-size: auto auto;
    background-size: cover;
    -moz-background-size: cover;
    background-attachment: fixed;
    .overlay {
        background-color: rgba(064,158,210, 0.8);
        height: 680px;
        section {
        padding-top: 100px;
        padding-bottom: 100px;
        }

    /*-------------------------------*/
    /*    Carousel Fade Transition   */
    /*-------------------------------*/

        #fade-quote-carousel.carousel {
            padding-bottom: 60px;
        }
        #fade-quote-carousel.carousel .carousel-inner .item {
            opacity: 0;
            -webkit-transition-property: opacity;
            -ms-transition-property: opacity;
            transition-property: opacity;
        }
        #fade-quote-carousel.carousel .carousel-inner .active {
            opacity: 1;
            -webkit-transition-property: opacity;
            -ms-transition-property: opacity;
            transition-property: opacity;
        }
        #fade-quote-carousel.carousel .carousel-indicators {
            bottom: 10px;
        }
        #fade-quote-carousel.carousel .carousel-indicators > li {
            background-color: #ffffff;
            border: none;
            margin-top: 20px;   
        }
        #fade-quote-carousel blockquote {
            text-align: center;
            border: none;
            margin-bottom: 80px;
            font-size: 25px;
        }
        #fade-quote-carousel .profile-circle {
            width: 300px;
            height: 300px;
            margin: 0 auto;
            border-radius: 100px;
            background-repeat: no-repeat;
            background-size: 350px 350px;
            border-radius: 50%;
        }
    }
}
.carousel-control.left {
    background-image: none;
    background-repeat: repeat-x;
    right: auto;
    left: 0;
    .glyphicon-chevron-left{
        left: 50%;
    }
}

.carousel-control.right {
    background-image: none;
    background-repeat: repeat-x;
    left: auto;
    right: 0;
    .glyphicon-chevron-right{
        right: 50%;
    }
}

.zitat {
    height: 680px;
    padding-left: 0;
    padding-right: 0;
    color: #fff;
}