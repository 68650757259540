.header-slider{
    width: 100%;
    height: 100%;
    height: calc(100% - 1px);
    background-color: #000;
    margin-top: -150px;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;

    
    ul{
      list-style: none;
    }

    .cb-slideshow,
    .cb-slideshow:after {
        width: 100%;
        z-index: 0px;
    }
    .balken {
    position: absolute;
    bottom: 0px;
    background-color: rgba(064,158,210, 0.9);
    width: 100%;
    height: 120px;
      h3{
        color: #fff;
        font-size: 24px;
        width: 250px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 20px;
        font-family: 'Quicksand', sans-serif;
        font-weight: lighter;
      }
    }
    
    .cb-slideshow:after {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(000, 000, 000, 0.0);
    }
   
    .cb-slideshow{ 
      li{ 
        span{
          width: 100%;
          height: calc(100% - 1px);;
          position: absolute;
          top: 0px;
          left: 0px;
          color: transparent;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: none;
          opacity: 0;
          z-index: 0;
          -webkit-backface-visibility: hidden;
          -webkit-animation: imageAnimation 24s linear infinite 0s;
          -moz-animation: imageAnimation 24s linear infinite 0s;
          -o-animation: imageAnimation 24s linear infinite 0s;
          -ms-animation: imageAnimation 24s linear infinite 0s;
          animation: imageAnimation 24s linear infinite 0s;
        }
        div{
          z-index: 1000;
          position: absolute;
          top: 200px;
          left: 20px;
          width: 90%;
          opacity: 0;
          color: #fff;
          -webkit-animation: titleAnimation 24s linear infinite 0s;
          -moz-animation: titleAnimation 24s linear infinite 0s;
          -o-animation: titleAnimation 24s linear infinite 0s;
          -ms-animation: titleAnimation 24s linear infinite 0s;
          animation: titleAnimation 24s linear infinite 0s;
          h3{
            font-size: 25px;
            color: #fff;
            text-align: center;
          }
        }
      }
    }

    .header-c2a{
      width: 300px;
      padding-top: 20px;
      padding-bottom: 20px;
      border: 2px solid $ksm-red;
      background-color: rgba(255, 255, 255, 0.8);
      @include bottom();
      text-align: center;
      color: $ksm-red;
      font-size: 22px;
    }

    .fernrohr {
      bottom: 0;
      margin-bottom: -120px;
      position: absolute;
      right: 20%;
    }
   
    .cb-slideshow li:nth-child(1) span { background-image: url(../images/start.jpg) }
    

    /* Animation for the slideshow images */
    @-webkit-keyframes imageAnimation { 
      0% {
          opacity: 1;
          -webkit-animation-timing-function: ease-in;
      }
      13% {
          opacity: 1;
          -webkit-transform: scale(1.05);
      }
      26% {
          opacity: 1;
          -webkit-transform: scale(1.1);
      }
      39% {
          opacity: 1;
          -webkit-transform: scale(1.1);
      }
      100% { opacity: 1; }
    }
    @-moz-keyframes imageAnimation { 
      0% {
          opacity: 1;
          -moz-animation-timing-function: ease-in;
      }
      13% {
          opacity: 1;
          -moz-transform: scale(1.05);
      }
      26% {
          opacity: 1;
          -moz-transform: scale(1.1);
      }
      39% {
          opacity: 1;
          -moz-transform: scale(1.1);
      }
      100% { opacity: 1; }
    }
    @-o-keyframes imageAnimation { 
      0% {
          opacity: 1;
      }
      13% {
          opacity: 1;
          -o-transform: scale(1.05);
      }
      26% {
          opacity: 1;
          -o-transform: scale(1.1);
      }
      39% {
          opacity: 1;
          -o-transform: scale(1.1);
      }
      100% { opacity: 1; }
    }
    @-ms-keyframes imageAnimation { 
      0% {
          opacity: 1;
          -ms-animation-timing-function: ease-in;
      }
      13% {
          opacity: 1;
          -ms-transform: scale(1.05);
      }
      26% {
          opacity: 1;
          -ms-transform: scale(1.1);
      }
      29% {
          opacity: 1;
          -ms-transform: scale(1.1);
      }
      100% { opacity: 1; }
    }
    @keyframes imageAnimation { 
      0% {
          opacity: 1;
          animation-timing-function: ease-in;
      }
      13% {
          opacity: 1;
          transform: scale(1.05);
      }
      26% {
          opacity: 1;
          transform: scale(1.1);
      }
      39% {
          opacity: 1;
          transform: scale(1.1);
      }
      100% { opacity: 1; }
    }
    /* Animation for the title */
    @-webkit-keyframes titleAnimation { 
      0% {
          opacity: 1;
         
      }
      13% {
          opacity: 1;

      }
      26% {
          opacity: 1;
      }
      28% { opacity: 1; }
      39% {
          opacity: 1;
      }
      100% { opacity: 1; }
    }
    @-moz-keyframes titleAnimation { 
      0% {
          opacity: 1;

      }
      13% {
          opacity: 1;

      }
      26% {
          opacity: 1;
      }
      28% { opacity: 1; }
      39% {
          opacity: 1;
      }
      100% { opacity: 1; }
    }
    @-o-keyframes titleAnimation { 
      0% {
          opacity: 1;
 
      }
      13% {
          opacity: 1;

      }
      26% {
          opacity: 1;
      }
      28% { opacity: 1; }
      39% {
          opacity: 1;
      }
      100% { opacity: 1; }
    }
    @-ms-keyframes titleAnimation { 
      0% {
          opacity: 1;

      }
      13% {
          opacity: 1;

      }
      26% {
          opacity: 1;
      }
      28% { opacity: 1; }
      39% {
          opacity: 1;
      }
      100% { opacity: 1; }
    }
    @keyframes titleAnimation { 
      0% {
          opacity: 1;

      }
      13% {
          opacity: 1;

      }
      26% {
          opacity: 1;
      }
      28% { opacity: 1; }
      39% {
          opacity: 1;
      }
      100% { opacity: 1; }
    }
    /* Show at least something when animations not supported */
    .no-cssanimations .cb-slideshow li span{
      opacity: 1;
    }
}


/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 
  .header-slider{
        /* Animation for the slideshow images */
      .balken {
        position: absolute;
        bottom: 0px;
        background-color: rgba(064,158,210, 0.9);
        width: 100%;
        height: 120px;
        h3{
          color: #fff;
          font-size: 30px;
          width: 400px;
          margin-right: auto;
          margin-left: auto;
          padding-top: 15px;
        }
      }
    
    

        .cb-slideshow{ 
      li{ 
        span{
          width: 100%;
          height: calc(100% - 1px);;
          position: absolute;
          top: 0px;
          left: 0px;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: none;
          opacity: 0;
          z-index: 0;
          -webkit-backface-visibility: hidden;
          -webkit-animation: imageAnimation 24s linear infinite 0s;
          -moz-animation: imageAnimation 24s linear infinite 0s;
          -o-animation: imageAnimation 24s linear infinite 0s;
          -ms-animation: imageAnimation 24s linear infinite 0s;
          animation: imageAnimation 24s linear infinite 0s;
        }
        div {
          z-index: 1000;
          position: absolute;
          top: 260px;
          left: 50px;
          width: 100%;
          opacity: 0;
          color: #fff;
          -webkit-animation: titleAnimation 24s linear infinite 0s;
          -moz-animation: titleAnimation 24s linear infinite 0s;
          -o-animation: titleAnimation 24s linear infinite 0s;
          -ms-animation: titleAnimation 24s linear infinite 0s;
          animation: titleAnimation 24s linear infinite 0s;
          h3{
            font-size: 28px;
            color: #fff;
            width: 320px;
          }
          h4{
            width: 320px;
          }
        }
      }
    }

    .fernrohr {
      bottom: 0;
      margin-bottom: -120px;
      position: absolute;
      right: -40%;
      img{
        width: 55%;
      }
    }

   /* Animation for the slideshow images */
    @-webkit-keyframes imageAnimation { 
      0% {
          opacity: 1;
          -webkit-animation-timing-function: ease-in;
      }
      13% {
          opacity: 1;
          -webkit-transform: scale(1.05);
      }
      26% {
          opacity: 1;
          -webkit-transform: scale(1.1);
      }
      39% {
          opacity: 1;
          -webkit-transform: scale(1.1);
      }
      100% { opacity: 1; }
    }
    @-moz-keyframes imageAnimation { 
      0% {
          opacity: 1;
          -moz-animation-timing-function: ease-in;
      }
      13% {
          opacity: 1;
          -moz-transform: scale(1.05);
      }
      26% {
          opacity: 1;
          -moz-transform: scale(1.1);
      }
      39% {
          opacity: 1;
          -moz-transform: scale(1.1);
      }
      100% { opacity: 1; }
    }
    @-o-keyframes imageAnimation { 
      0% {
          opacity: 1;
      }
      13% {
          opacity: 1;
          -o-transform: scale(1.05);
      }
      26% {
          opacity: 1;
          -o-transform: scale(1.1);
      }
      39% {
          opacity: 1;
          -o-transform: scale(1.1);
      }
      100% { opacity: 1; }
    }
    @-ms-keyframes imageAnimation { 
      0% {
          opacity: 1;
          -ms-animation-timing-function: ease-in;
      }
      13% {
          opacity: 1;
          -ms-transform: scale(1.05);
      }
      26% {
          opacity: 1;
          -ms-transform: scale(1.1);
      }
      29% {
          opacity: 1;
          -ms-transform: scale(1.1);
      }
      100% { opacity: 1; }
    }
    @keyframes imageAnimation { 
      0% {
          opacity: 1;
          animation-timing-function: ease-in;
      }
      13% {
          opacity: 1;
          transform: scale(1.05);
      }
      26% {
          opacity: 1;
          transform: scale(1.1);
      }
      39% {
          opacity: 1;
          transform: scale(1.1);
      }
      100% { opacity: 1; }
    }
    /* Animation for the title */
    @-webkit-keyframes titleAnimation { 
      0% {
          opacity: 1;
         
      }
      13% {
          opacity: 1;

      }
      26% {
          opacity: 1;
      }
      28% { opacity: 1; }
      39% {
          opacity: 1;
      }
      100% { opacity: 1; }
    }
    @-moz-keyframes titleAnimation { 
      0% {
          opacity: 1;

      }
      13% {
          opacity: 1;

      }
      26% {
          opacity: 1;
      }
      28% { opacity: 1; }
      39% {
          opacity: 1;
      }
      100% { opacity: 1; }
    }
    @-o-keyframes titleAnimation { 
      0% {
          opacity: 1;
 
      }
      13% {
          opacity: 1;

      }
      26% {
          opacity: 1;
      }
      28% { opacity: 1; }
      39% {
          opacity: 1;
      }
      100% { opacity: 1; }
    }
    @-ms-keyframes titleAnimation { 
      0% {
          opacity: 1;

      }
      13% {
          opacity: 1;

      }
      26% {
          opacity: 1;
      }
      28% { opacity: 1; }
      39% {
          opacity: 1;
      }
      100% { opacity: 1; }
    }
    @keyframes titleAnimation { 
      0% {
          opacity: 1;

      }
      13% {
          opacity: 1;

      }
      26% {
          opacity: 1;
      }
      28% { opacity: 1; }
      39% {
          opacity: 1;
      }
      100% { opacity: 1; }
    }

    /* Show at least something when animations not supported */
    .no-cssanimations .cb-slideshow li span{
      opacity: 1;
    }
  }
  .navbar-default{
    //background-color: green!important;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  .navbar-default{
    //background-color: blue!important;
  }
  .fernrohr{
    right: 35%;
    img{
      width: 50%;
      height: auto;
    }
  }
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 
  .navbar-default{
    //background-color: red!important;
  }
  .header-slider{
    .cb-slideshow{ 
      li{ 
        span{
       
        }
        div{        
          h3{
            font-size: 37px;
            color: #fff;
            line-height: 48px;
            width: 530px;
            text-align: center;
          }
          h4{
            font-size: 25px;
            width: 530px;
            text-align: center;
          }
        }
      }
    }
    .balken{
    height: 135px;
      h3{
        font-size: 40px;
        width: 400px;
        margin-left: 60px;
        margin-top: 10px;
      }
    }
    .fernrohr{
      right: 0%;
      img{
        width: 750px;
        height: auto;
      }
    }
  }
}

@media (min-width: $screen-xl-min) {  
  .navbar-default{
    //background-color: pink!important;
  }
  .header-slider{
    .cb-slideshow{ 
      li{ 
        span{
       
        }
        div{        
          h3{
            font-size: 62px;
            color: #fff;
            line-height: 80px;
            width: 840px;
            text-align: center;
          }
          h4{
            font-size: 40px;
            width: 840px;
            text-align: center;
                    line-height: 53px;
          }
        }
      }
    }
    .fernrohr{
      right: 12%;
      img{
        width: 80%;
        height: auto;
      }
    }
  }
}