h1 span {
    font-size: 30px;
}
.map-balken{
	text-align: center;
	background-color: $ksm-red;
	width: 100%;
	font-size: 25px;
	h3{
		padding-bottom: 10px;
		margin-bottom: 0;
		padding-top: 10px; 
		color: #fff;
	}
}

.kontakt-ankuendigung{
	text-align: center;
	h2{
		color: $ksm-red;
		font-size: 30px;
		margin-top: 80px;
	}
	span{
		color: $ksm-grey;
	}
}

#keywords{
	margin-left: -100px;
}

.start-services{
	margin-top: 50px;
	margin-bottom: 50px;
	.box{
		border: 1px solid $ksm-red;
		padding: 40px 20px 40px 20px;
		display: block;
		text-decoration: none;
		margin-bottom: 20px;
		text-align: left;
		color: $ksm-grey;
		margin-left: auto;
		margin-right: auto;
		max-width: 300px;
		min-height: 420px;
		.icon{
			width: 70px;
			height: 70px;
			margin-left: auto;
			margin-right: auto;	
			background-color: $ksm-red;
			border-radius: 50%;
			i{
				display: block;
				@include center();
				color: #fff;
				font-size: 40px;
			}			
		}
		.box-description{
			padding-top: 20px;
			ul{
				color: $ksm-red;
				margin-bottom: 20px;
				li{
					margin-bottom: 10px;
					font-weight: 400;
					span{
						color: $ksm-grey;
					}
				}
			}
		}
		.box-c2a{
				text-align: center;
			    border: 1px solid $ksm-red;
			    color: $ksm-red;
			    margin-top: 15px;
			     @include cta();
			     padding-left: 20px;
			     padding-right:20px;
		}
		.box-headline{
			text-align: center;
			h2{
				font-size: 18px;
				color: $ksm-red; 
				margin-top: 0px;
				margin-bottom: 0px;
				text-align: center;
				padding-top: 20px;
				margin-left: auto;
				margin-right: auto;
			}		
			.subline{
				p{
				text-align: center;
				margin-top: 30px;
				}
			}
		}
	}

}

.potrait{
	margin-top: 50px;
	margin-bottom: 50px;
	.box{
		border: 1px solid $ksm-red;
		padding: 40px 20px 40px 20px;
		display: block;
		text-decoration: none;
		color: $ksm-grey;
		margin-bottom: 20px;
		text-align: left;
		min-height: 400px;
		.icon{
			width: 135px;
			height: 70px;
			border-radius: 50%; 
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
			img{
				border-radius:50%;
				border: 1px solid $ksm-red;
				margin-top: -20px;
			}
		}
		.box-description{
			text-align: left;
			ul{
				color: $ksm-red;
				span{
					color: $ksm-grey;
				}
			}
		}
		.portrait_cta_1{
			border: 1px solid $ksm-red;
			text-align: center;
			color: $ksm-red;
			width: 180px;
			margin-left: auto;
			margin-right: auto;

		}
		.portrait_cta_2{
			border: 1px solid $ksm-red;
			text-align: center;
			color: $ksm-red;
			width: 180px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 15px;

		}
		.box-c2a{
				text-align: center;
			    border: 1px solid $ksm-red;
			    width: 180px;
			    margin-left: auto;
			    margin-right: auto;
			    color: $ksm-red;
			    @include cta();
			}
		.box-c2a-vcard{
				@include ctatwo();
				text-align: center;
			    border: 1px solid $ksm-red;
			    width: 180px;
			    margin-left: auto;
			    margin-right: auto;
			    color: $ksm-red;
		}
		.box-headline{
			text-align: center;
			h2{
				font-size: 20px;
				color: $ksm-red; 
				margin-top: 0px;
				margin-bottom: 0px;
				text-align: center;
				padding-top: 20px;
				margin-left: auto;
				margin-right: auto;
			}		
			.subline{
				p{
				text-align: center;
				margin-top: 30px;
				}
			}
		}
	}
}

.start-referenzen-headline{
	h3{
		text-align: center;
		font-size: 35px;
		color: $ksm-grey-medium;
		margin-bottom: 30px;
		margin-top: 20px;
	}
}


.start-referenzen{
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: $ksm-grey-light;
	margin-bottom: 30px;
	.img-box{
		position: relative;
		min-height: 85px;
		img{
			position: absolute;
	  		left: 0;
	  		-webkit-transition: opacity 1s ease-in-out;
	  		-moz-transition: opacity 1s ease-in-out;
	  		-o-transition: opacity 1s ease-in-out;
	  		transition: opacity 1s ease-in-out;
	  		&.top:hover {
	  			opacity:0;
			}
		}
	}
	a{
		border: 2px solid $ksm-red;
		width: 40px;
		height: 40px;
		display: block;
		border-radius: 50%;
		position: relative;
		margin-top: 20px;
		i{
			color: $ksm-red;
			font-size: 25px;
			@include center();
		}
	}
}

.content-unterseite{

	margin-bottom: 50px;
	p{
		padding-top: 10px;
		a{
			color: $ksm-blue;
		}
	}
}

.sidebar-box{
	max-width: 300px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
	color: $ksm-grey;
	border: 1px solid $ksm-red;
	text-align:center;
	.sidebar-inner{
		margin: 15px 30px;
		h3{
			color: $ksm-grey;
			text-align: left; 
			font-size: 20px;
		}
		a{
			color: $ksm-grey;
			text-align: left;
		}
		.sidebar-name{
			font-weight: bold;
			text-align: left;
		}
		.sidebar-position{
			text-align: left;
			font-size: 13px;
		}
		.contact {
    		text-align: left;
    		i{
    			padding-right: 15px;
    		}
		}
	}
}



.start-text{
	p{
		padding-top: 10px;
		a{
			color: $ksm-blue;
		}
	}
	h1{
		color: $ksm-red;
		border-right: 0px solid $ksm-grey;
	}
}



.action-strip-kompetenz{
	background-color: $ksm-blue;
	color: #fff;
	padding-top: 10px;
	padding-bottom: 35px;
	padding-right: 0px;
	padding-left: 0px;
	strong {
    	font-size: 32px;
    	font-weight: 400;
    	letter-spacing: 5px;
	}
	h2{
		font-size: 25px;
	}
	.cta-main{
		border: 1px solid #fff;
	    font-size: 23px;
	    padding-bottom: 20px;
	    padding-left: 20px;
	    padding-right: 20px;
	    padding-top: 20px;
	    text-align: center;
	    color: #fff;
	    letter-spacing: 2.5px;
	}

	.dreieck {
 		width: 0;
  		height: 0;
  		border-left: 50px solid transparent;
  		border-right: 50px solid transparent;
 		border-top: 50px solid $ksm-blue;
 		margin-left: auto;
 		margin-right: auto;
 		margin-bottom: -80px;
	}
}

.kompetenz-active{
	background-color: $ksm-red;
	color: #fff;
	a{
		color: #fff!important;
	}
	h3{
		color: #fff;
	}
}

.kompetenz-box{
	position: relative;
	height: 100px;
	border: 1px solid $ksm-red;
	margin-top: 50px;
	margin-bottom: 50px;
	color: $ksm-red !important;
	&:hover{
		background-color: $ksm-red;
		color: #fff;
		h3{
			color: #fff;
		}
		a{
			color: #fff;
		}
	}
	h3{
		font-size: 20px;
		margin: 0px;
		&:hover{
			color: #fff;
		}
	}
	a{
	    color: $ksm-red;
	    display: block;
	    text-align: center;
	    width: 100%;
	    @include center();
	    text-decoration: none;
	    &:hover{
	    	color: #fff!important;
	    }
	}
}

.unterseite-zitat{
	margin-top: 13px;
	text-align: center;
	color: $ksm-red;
	font-size: 23px; 
}

.karriere{
	h2{
		color: $ksm-red;
		text-align: left;
	}
	.vakanz-container{
		margin-top: 80px;
	}
}

.is-checked{
	background-color: $ksm-red;
	color: #fff !important;
}

.ansprechpartner{
	h1{
		text-align: center;
		margin-bottom: 50px;
	}
	.ansprechpartner-box{
		border: 1px solid $ksm-red;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 80px;
		h2{
			color: $ksm-red;
			text-align: left;
			font-size: 25px;
			margin-top: 0px;
		}
		.ansprechpartner-content{



			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 40px;
			padding-top: 40px;
			a{
				color: $ksm-blue;
			}
			.ansprechpartner-position{
				font-size: 22px;
				margin-bottom: 20px;
			}

			.ansprechpartner-kompetenz {
	   			padding-bottom: 20px;
			}
			.ansprechpartner-beschreibung {
	    		padding-bottom: 15px;
			}
		}
	}
}

.col-md-6{
	#nf-form-2-cont {
    margin-top: 70px;
	}
}

.download-fields{
	.download{
		color: $ksm-grey;
		text-decoration: none;
		padding-top: 8px;
		font-size: 13px;

		img{
			border: 1px solid #ccc;
			width: 100%;
			height: auto;
			display: block;
		}
		.download-description{
			position: relative;
		}
	}
}


/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-sm-min) { 


	.start-text{
		h1{
			font-size: 40px;
		}
	}

	h1 span {
    font-size: 40px;
	}


	.sidebar-box{
		max-width: 100%;
	}

	.start-services{
		.box{
			margin-left: auto;
			margin-right: auto; 
			width: 500px;
			max-width: none;
			.box-headline{
				h2{
					font-size: 25px;
					color: $ksm-red; 
				}
			}
			.box-description{
				margin-left: 35px;
				margin-top: 15px;
				ul{
					color: $ksm-red;
					min-height: 130px;
					span{
						color: $ksm-grey;
					}
				}
			}
			.icon{
				margin-left: 20px;
				i{

				}
			}
		}
	}
	.potrait{
		.box{
			min-height: 400px;
			.box-headline{
				h2{
					font-size: 25px;
					color: $ksm-red; 
				}
			}
			.box-description{
				margin-left: 35px;
				margin-top: 15px;
				ul{
					color: $ksm-red;
					min-height: 130px;
					span{
						color: $ksm-grey;
					}
				}
			}
		}
	}

	.content-unterseite{

	margin-bottom: 100px;
	p{
		padding-top: 0px;
		a{
			color: $ksm-blue;
		}
	}
}

#keywords{
	margin-left: 0px;
}

	.start-text{

	margin-bottom: 100px;
	p{
		padding-top: 0px;
		a{
			color: $ksm-blue;
		}
	}
}

.action-strip-kompetenz{
	background-color: $ksm-blue;
	color: #fff;
	padding-top: 30px;
	padding-bottom: 50px;
	padding-right: 0px;
	padding-left: 0px;
	strong {
    	font-size: 32px;
    	font-weight: 400;
    	letter-spacing: 5px;
	}
	h2{
		font-size: 30px;
	}
	.cta-main{
		border: 1px solid #fff;
	    font-size: 23px;
	    padding-bottom: 20px;
	    padding-left: 20px;
	    padding-right: 20px;
	    padding-top: 20px;
	    text-align: center;
	    color: #fff;
	    letter-spacing: 2.5px;
	}

	.dreieck {
 		width: 0;
  		height: 0;
  		border-left: 50px solid transparent;
  		border-right: 50px solid transparent;
 		border-top: 50px solid $ksm-blue;
 		margin-left: auto;
 		margin-right: auto;
 		margin-bottom: -80px;
	}

}



}


@media (min-width: $screen-md-min) {

	.potrait{
		.box{
			min-height: 440px;
			.portrait_cta_2{
				margin-top: 0px;
			}
		}
	}
  .sidebar-box{
			margin-top: 0px;
	}
	.start-text{
	h1{
		font-size: 50px;
	}
	}
	.start-services{
		.box{
			margin-left: auto;
			margin-right: auto; 
			width: auto;
			max-width: none;
			.box-headline{
				h2{
					font-size: 21px;
				}
			}
		}
	}

}



/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 




#keywords{
	margin-left: 0px;
}


.start-text{
	h1{
		border-right: 1px solid $ksm-grey;
	}
}



.start-services{
	margin-top: 50px;
	margin-bottom: 50px;
	.box{
		.box-headline{
				h2{
					font-size: 25px;
				}
			}
		position: relative; 
		border: 1px solid $ksm-red;
		padding: 40px 20px 40px 20px;
		-webkit-transition: all ease 0.8s;
		-moz-transition: all ease 0.8s;
		transition: all ease 0.8s;
		display: block;
		text-decoration: none;
		color: $ksm-grey;
		margin-bottom: 20px;
		text-align: left;
		&:hover{
			text-decoration: none;
			color: #fff;
			box-shadow: inset 0 -500px 0 0 $ksm-red;
			h2{
				color: #fff !important;
			}
			.icon{
				border: 2px solid $ksm-red;
				background-color:  #fff;
				color: $ksm-red;
				i{
				color: $ksm-red;
				font-size: 40px; 
				}
			}
			.box-description{
				color: #fff;
				ul{
					color: #fff;
					span{
						color: #fff;
					}
				}
			}
			.box-c2a{
				color: #fff;
				border: 1px solid #fff;
			}
		}
		.icon{
			width: 70px;
			height: 70px;
			//margin-left: auto;
			//margin-right: auto;	
			background-color: $ksm-red;
			border-radius: 50%;
			position: relative; 
			margin-left: 60px;
			i{
				display: block;
				@include center();
				color: #fff;
				font-size: 40px;
			}
		}
		.box-headline{
			
			h2{
				font-size: 25px;
				color: $ksm-red; 
				margin-top: 0px;
				margin-bottom: 0px;
				text-align: left;
			}
			.subline{
				text-align: left;
			}
		}
		.box-description{
			margin-left: 35px;
			margin-top: 15px;
		}	
		.box-c2a{
				text-align: center;
			    border: 1px solid $ksm-red;
			    width: 250px;
			    margin-left: auto;
			    margin-right: auto;
			    color: $ksm-red;
			    margin-top: 35px;
		}
	}
}

.potrait{
	margin-top: 50px;
	margin-bottom: 50px;
	.box{
		.row{
			margin-bottom: 35px;
		}	
		position: relative; 
		border: 1px solid $ksm-red;
		padding: 40px 20px 40px 20px;
		-webkit-transition: all ease 0.8s;
		-moz-transition: all ease 0.8s;
		transition: all ease 0.8s;
		display: block;
		text-decoration: none;
		color: $ksm-grey;
		margin-bottom: 20px;
		text-align: left;
		.icon{
			width: 135px;
			height: 70px;
			border-radius: 50%;
			position: relative;
			img{
				border-radius:50%;
				border:1px solid $ksm-red;
				margin-top:-20px;
			}
		}
		.box-headline{
			
			h2{
				font-size: 25px;
				color: $ksm-red; 
				margin-top: 0px;
				margin-bottom: 0px;
				text-align: left;
			}
			.subline{
				text-align: left;
			}
		}
		.box-description{
			margin-left: 35px;
			margin-top: 15px;
		}
		.box-c2a {
			text-align: center;
		    border: 1px solid $ksm-red;
		    width: 180px;
		    margin-left: auto;
		    margin-right: auto;
		    color: $ksm-red;
		}
		.box-c2a-vcard{
			text-align: center;
			border: 1px solid $ksm-red;
		    width: 180px;
		    margin-left: auto;
		    margin-right: auto;
		    color: $ksm-red;
		}
	}
}


}

