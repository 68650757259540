.footer{
}
#mapWrapper{
	height: 520px;
}

.footer-top{
	background-color: $ksm-red;
	color: #fff;
    padding-bottom: 80px;
    padding-top: 80px;
    a{
    	color: #fff;
    	padding: 5px 0 5px 0 !important;
    	&:hover{
    		background-color: $ksm-red !important;
    		text-decoration: underline;
    	}
    }
}
.footer-bottom{
	/*margin-bottom: 100px;*/
	ul{
		padding-left: 0;
		a{
			float: left;
			display: inline;
			color: $ksm-red;
		}
	}
}

#cookie-box {
    display: none;
    z-index: 9000;
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(41,145,204,0.8);
    color: #ffffff;
    .container {
        padding-top: 23px;
    }
    .cookie-link,
    .link {
        cursor: pointer;
        float: right;
        margin: 8px 0 10px 10px;
        padding: 7px 18px 6px 18px;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 14px;
        line-height: 20px;
        font-family: 'Quicksand', $font-family-base;
        border: 1px solid #ffffff;
        background: #2991CC;
        &:hover {
            text-decoration: none;
        }
    }
    p {
        float: left;
        width: 70%;
        padding: 0 0 29px 0;
        margin-bottom: 0;
    }
}