html{
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    .page-template-template-home {
        margin-top: 150px;
        .footer{
            margin-bottom: 150px;
        }  
    }
    body{
        height: 100%;
        overflow-x: hidden;
        font-family: 'Quicksand', sans-serif;
        color: #3e3e3e;
        font-size: 16px;
        font-weight: lighter;
        line-height: 26px;
        /*margin-top: 150px;*/
        h1{
            text-align: left;
            color: $ksm-red;
            line-height: 26px;
            font-size: 24px;
            margin-top: 0px;
            font-weight: lighter;
            line-height: 36px;
        }
        h2{
            text-align: center;
            text-transform: uppercase;
            padding-bottom: 5px;
            font-weight: lighter;
            margin-bottom: 20px;
            span{
                font-weight: bold;
            }
        }
        h3{
            color: #3e3e3e;
        }
        h4{
            line-height: 36px;
        }
        hr{
            max-width: 80px;
            color: #3e3e3e;
            background-color: #3e3e3e;
            border: 1px solid;
        }
        a{
            text-decoration: none;
        }
        img{
            width: 100%;
            height: auto;
        }

        .prepage-headline{
            h1{
            text-align: center;
            text-transform: uppercase;
            padding-bottom: 5px;
            font-weight: lighter;
            margin-bottom: 20px;
            span{
                font-weight: bold;
            }
        }
        }
    }
    .wrap{
        background-color: #fff;
        position: relative;
        padding-top: 30px;
    }
}


@mixin center($x: true, $y: true) {
    position: absolute;

    $yPos: 0;
    $xPos: 0;

    @if $x {
        $xPos: -50%;
        left: 50%;
    }

    @if $y {
        top: 50%;
        $yPos: -50%;
    }

    transform: translate($xPos, $yPos);
    transform: translate3d($xPos, $yPos, 0);
}

@mixin cta($x: true, $y: true) {
    position: absolute;

    $yPos: 0;
    $xPos: 0;

    @if $x {
        $xPos: -50%;
        left: 50%;
    }

    @if $y {
        bottom: 10%;
        $yPos: -10%;
    }

    transform: translate($xPos, $yPos);
    transform: translate3d($xPos, $yPos, 0);
}

@mixin ctaa($x: true, $y: true) {
    position: absolute;

    $yPos: 0;
    $xPos: 0;

    @if $x {
        $xPos: -50%;
        left: 50%;
    }

    @if $y {
        bottom: 10%;
        $yPos: -10%;
    }

    transform: translate($xPos, $yPos);
    transform: translate3d($xPos, $yPos, 0);
}

@mixin ctatwo($x: true, $y: true) {
    position: absolute;

    $yPos: 0;
    $xPos: 0;

    @if $x {
        $xPos: -50%;
        left: 50%;
    }

    @if $y {
        bottom: -18px;;
        $yPos: 18px;
    }

    transform: translate($xPos, $yPos);
    transform: translate3d($xPos, $yPos, 0);
}

@mixin bottom($x: true, $y: true) {
    position: absolute;

    $yPos: 0;
    $xPos: 0;

    @if $x {
        $xPos: -50%;
        left: 50%;
    }

    @if $y {
        bottom: 5%;
        $yPos: -5%;
    }

    transform: translate($xPos, $yPos);
    transform: translate3d($xPos, $yPos, 0);
}





/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 

html{
    .wrap{
        background-color: #fff;
        position: relative;
        padding-top: 100px;
    }
    body{
         h1{
            text-align: left;
            color: $ksm-grey;
            line-height: 71px;
            font-size: 35px;
            margin-top: 0px;
            font-weight: lighter;
            span{
                color: $ksm-red;
            }
        }
    }
}

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 
 
}

@media (min-width: $screen-xl-min) {  
 
}