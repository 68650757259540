// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.nf-form-fields-required{
    padding-left: 5px;
    padding-bottom: 10px;
}
.nf-error-msg{
	color: $ksm-red !important;
}

.ninja-forms-req-symbol, .nf-error-msg{
	color: $ksm-red !important;
}

.nf-error .ninja-forms-field{
	border: 1px solid $ksm-red;
}

.nf-form-layout{
  input{
    height: 40px!important;
    background-color: #EAEDF2;
    border: 0px;
    padding-left: 20px;
  }
  textarea{
    min-height: 235px;
    background-color: #EAEDF2;
    border: 0px;
    padding-top: 20px;
    padding-left: 20px;
  }
  .checkbox-container{
    input{
      height: 20px!important;
    }
  }
  .listselect-wrap{
    select{
      background-color: #EAEDF2;
      border: 0px;
      padding-left: 20px;
      height: 60px;
    }
  }
  .submit-wrap{
    text-align: center;
    input{
      background-color: #fff;
      border: 1px solid $ksm-red;
      width: 250px!important;
      text-align: center;
      color: $ksm-red;
      margin-bottom: 50px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
