
ul{
  margin-bottom: 4em;
}

.counter-wrap{
    position: relative;
    .zahl{
      @include center();
      height: 145px;
      .counter {
        color: $ksm-red;
        font-size: 60px;
        text-align: center;

      }
      .einheit {
        color: $ksm-red;
        font-size: 60px;
        text-align: center;
      }
    }
    .counter-beschreibung {
      display: block;
      font-size: 32px;
      font-weight: 900;
      margin-top: 30px;
      text-align: center;
      color: $ksm-red;
      margin-top: 90px;
      line-height: 36px;
    }
}

.referenzen{
  h1{
    text-align: left;
  }
  p{
    text-align: left;
    margin-bottom: 50px;
  }
}

.filter-button-group{
  a{
    color: #fff;
    width: auto;
    position: relative;
    padding: 20px;
    border-radius: 0px;
    border: 1px solid $ksm-red;
    color: $ksm-red;
    font-weight: 900;
    -webkit-transition: all ease 0.8s;
    -moz-transition: all ease 0.8s;
    transition: all ease 0.8s;
    &:hover{
      background-color: $ksm-red;
      color: #fff;
      box-shadow: inset 0 -100px 0 0 $ksm-red;
    }
    span {
      display: inline-block;
      font-size: 20px;
      padding-right: 10px;
    }
  }
}

.grid{
  margin-bottom: 100px;
}

.grid-item { 
  width: 96%;
  margin: 2%;
  border: 1px solid $ksm-red;
  padding: 20px 30px 20px 30px;
}

.btn.button {
      width: 96%;
      margin: 2%;
  }

.fa fa-phone{
  color: $ksm-red;
}


/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 

.grid-item { 
  width: 42%;
  margin: 2%;
}

.btn.button {
      width: 48%;
      margin: 2%;
  }



}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 


.grid-item { 
  width: 20%;
  margin: 2%;
}

.btn.button {
      margin-right: 20px;
      width: 20%;
      margin: 2%;
  }


 }

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 


}

@media (min-width: $screen-xl-min) { 

   
}

