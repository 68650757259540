    .navbar-brand {
    height: 50px;
    padding-left: 0;
    padding-top: 0;
    width: 340px;
    margin-left: 20px!important;
    margin-top: 10px;
}

#navbar {
    margin-top: 1px;
}

.navbar-header{
    margin-bottom: 15px;
}

.header-top{
    height: 50px;
    .header-contact {
    	margin-top: 20px;
    	float: right;
    	margin-right: 30px;
    	.diver {
        	padding-left: 20px;
        	padding-right: 20px;
    	}
        li{
        	display: inline;
        	float: left;
        	width: auto;
        	font-size: 12px;
            cursor: pointer;
        }
    }
    img{
            width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
    }
}

.tel-mobile {
    background-color: $ksm-red;
    margin-left: -15px;
    margin-right: -15px;
    text-align: center;
    color: #fff;
    margin-bottom: -1px;
}

.navbar-nav-mobile {
    li {
        padding-left: 10px;
        a {
            padding-top: 8px;
            padding-bottom: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 1;
            transition: color .5s, background-color .5s;
            color: $ksm-grey;
            margin-top:10px;
            margin-bottom: 10px;
        }
        &.menu-item-has-children {
        @extend .dropdown;
            &.open {
                .sub-menu {
                    display: block;
                    width: 100%;
                    padding-left: 5px;
                    padding-right: 5px;
                    position: relative;
                }
                > a {
                    outline: 0;
                }
            }
        }
        .sub-menu {
            @extend .dropdown-menu;
            font-weight: normal;
            background-color: rgba(255, 255, 255, 0.0);
            color: #fff;
            border-top: 0;
            margin-top: 0;
            box-shadow: none;
            border-radius: 0;
            border: 0;
            > li > a {
                margin-top: 3px;
                font-weight: normal;
                padding-top: 7px;
                padding-bottom: 7px;
                background-color: rgba(255, 255, 255, 0.9);
            }
        }
    }
    .active{
        background-color: $ksm-red;
        color: #fff!important;
        a{
            color: #fff;
        }
    }
}

 .navbar-nav {
    margin-top: 15px;
    > li {
    	padding: 10px;
        > a {
        padding-top: 12px;
        padding-bottom: 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1;
        transition: color .5s, background-color .5s;
        color: $ksm-grey;
    }
    &.menu-item-has-children {
        @extend .dropdown;
    	    &.open {
                .sub-menu {
                    display: block;
                    width: 100%;
                    padding-left: 5px;
                    padding-right: 5px;
                    .active{
                        a{
                            background-color: $ksm-red !important;
                            &:hover{
                                background-color: $ksm-red !important;
                            }
                            &:focus{
                                background-color: $ksm-red !important;
                            }
                        }

                    }
                }
                > a {
                    outline: 0;
                }
            }
        }
        .sub-menu {
            @extend .dropdown-menu;
        	font-weight: normal;
        	background-color: rgba(204, 204, 204, 0.0);
            color: #fff;
            border-top: 0;
            margin-top: 0;
            box-shadow: none;
            border-radius: 0;
            border: 0;
            > li > a {
            	margin-top: 3px;
                font-weight: normal;
                padding-top: 7px;
                padding-bottom: 7px;
                background-color: rgba(231, 231, 231, 0.8);
            }
        }
    }
    .active{
        	border-top: 2px solid $ksm-red !important; 
    }
}




.small-header {
 	background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 160px;
    margin-top: 149px;
}

.anfahrt-header{
    background-position: 100%;
}

.referenzen-header{
    background-position: -210px;
}

.no-header{
    margin-top: 150px;
}


/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 
.header-top{
    height: 70px;
    img{
        width: 100%;
        height: auto;
    }
}

.no-header{
    margin-top: 90px;
}


.small-header {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
}

.anfahrt-header{
    background-position: center center;
}

.referenzen-header{
    background-position: center center;
}

.tel-mobile {
    background-color: $ksm-red;
    margin-left: -125px;
    margin-right: -125px;
    text-align: center;
    color: #fff;
    margin-bottom: -1px;
}



}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 

    .navbar-nav {
        li {
            a {
                font-size: 14px;
            }
        }

        .sub-menu{
            li{
                width: 230px;
            }
        }
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 

    .navbar-nav {
        li {
            a {
                font-size: 16px;
            }
        }
    }

}

@media (min-width: $screen-xl-min) { 


    .header-top{
        .header-contact {
            li{
                font-size: 14px;
            }
        }
        img{
            width: 100%;
            height: auto;
        }
    }
}

